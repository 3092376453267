

















import Vue from 'vue';

export default Vue.extend({
  components: {
    SecurityV1: () => import(/* webpackChunkName: "l-sec-v1" */ '@/components/legal/sec/V1.vue'),
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
